<template>
    <v-container>
        <v-row justify="center" class="pa-5">
                <h1 class="text-h3 font-weight-light">Privacy Policy</h1>
        </v-row>
        <v-row justify="center" class="mb-10">
            <h3 class="text-body-2 font-weight-light">2 April 2020</h3>
        </v-row>

        <v-row justify="center">
            <v-col cols="12" sm="10" md="10" lg="8" xl="8">
                <div class="text-body-2">
                    <p>
                        This privacy policy describes how Liberty Infrasystems LLC ("we", "us", "our") uses and protects information that it collects from you when you use our products and services, including but not limited to the website, network applications and their programming interfaces, desktop and mobile applications, and customer support services (collectively the "Service"). By accessing or using the Service you agree to the <router-link to='/about/terms'>terms and conditions</router-link> and to this privacy policy ("Policy"). The Policy describes the practices regarding the collection, protection, use, sharing, and disclosure of your information.
                    </p>
                    <p>
                        Liberty Infrasystems reserves the right to modify this policy at any time. When we make such modifications, we will announce the changes on our site and attempt to notify all registered users via email. Your continued use of the Service after any such changes constitutes your acceptance of the new privacy policy. If you do not agree to this policy or to any future modifications, do not use or access the Service.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Summary</h2>
                    <p>
                        When you use the Service or ask for help with using the Service, we may collect personal information from you for the purpose of contacting you ("Contact Data") including but not limited to name, email address or other online identifier, telephone number, and mailing address. We do not sell your Contact Data to third parties. We may share your Contact Data with third parties for the purpose of communicating with you about any topic including but not limited to the Service and products and services provided by third parties such as other commercial, government, or non-profit organizations. We may also collect information about you including but not limited to your device and network information as it appears to us when you connect to the Service.
                    </p>
                    <p>
                        We may collect payment from you in connection with use of the Service, and to do that we may need to collect additional payment-related information from you ("Payment Data"). Some payment systems require collection of contact information as part of the Payment Data, including but not limited to name, email address, and mailing address. We handle this information separately from Contact Data that we collect. When you provide the same information for Contact Data and Payment Data, each copy of the information is handled according to the rules of the information category under which it was collected, as described in this policy.
                    </p>
                    <p>
                        We may collect your preferences and opinions from you including but not limited to how you prefer to use the Service ("Preference Data"). Preference Data may be collected directly from you or via a third party by sharing your Contact Data for this purpose.
                    </p>
                    <p>
                        We may collect information about the type of device you use, a device identifier, operating system, network address, network provider, the software you use, including any information provided automatically by software that you are running in connection with the Service such as browser name and version, cookies, and the names of files you downloaded or viewed from the Service (collectively, "Visitor Data"). These are typically collected by most websites and apps either actively or passively.
                    </p>
                    <p>
                        We may create new information derived from the aggregate of all Authentication Data and Payment Data of all its users (collectively, "Aggregate Data").
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Aggregate Data</h2>
                    <p>
                        Aggregate Data is not intended to include information that identifies individual users, but this may occur. For example, we may prepare a summary report of all domain names to which users authenticate (Aggregate Data derived from selected Authentication Data). If you authenticate to a website whose domain name is your last name, that information may be included in the report because it is Authentication Data.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Tracking Technologies</h2>
                    <p>
                        We may use cookies or similar technologies to analyze trends, administer the Service, track user interaction with the Service, and gather demographic information about users. We do not respond to Do Not Track requests.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Legal Compliance</h2>
                    <p>
                        In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities. We will notify you of a legal process seeking access to your information, such as search warrants, court orders, or subpoenas, unless we are prohibited from doing so. In cases where a court order specifies a non-disclosure period, we will comply with the order and attempt to inform you after the order expires.
                    </p>
                    <p>
                        If you reside outside the United States, please note that information collected through the Service will be transffered to and processed in the United States or elsewhere. These locations may not have the same data protection laws as the country in which you initially used the Service.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Safety</h2>
                    <p>
                        We may disclose your personal information to third parties to investigate, prevent, or take action regarding suspected or actual illegal activities, to enforce our Terms and Conditions, to take precautions against liability, to investigate and defend ourselves against any claims or allegations, to protect the security or integrity of our Service, and to exercise or protect the rights, property, or personal safety of anyone, including including but not limited to ourselves and our customers.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Corporate Change</h2>
                    <p>
                        We may transfer or assign your information in the course of a corporate change or business transfer including, but not limited to, divestitures, mergers, or dissolution.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Data Retention</h2>
                    <p>
                        CrypWetium may retain your information, including but not limited to Contact Data, Authentication Data, Payment Data, Preference Data, and Visitor Data, for numerous reasons including but not limited to operation of the Service, business development, compliance with laws and regulations, dispute resolution, enforcement of our agreements, and investigation in response to customer support requests.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Minors</h2>
                    <p>
                        If we become aware that minor has opened an account or provided us with personal information without parental consent, we will delete the information.
                    </p>
                    <h2 class="text-h5 font-weight-light pa-4" style="text-align: center">Transparency</h2>
                    <p>
                        You can see the data that we store from your device here
                    </p>

                    <!-- <v-row justify="center">
                        <v-btn elevation="6" class="purple white--text" to='/me'>About Me</v-btn>
                    </v-row> -->
                </div>
            </v-col>
         </v-row>
  </v-container>
</template>

<style scoped>
* {
    line-height: 2.5
}
</style>

<script>
export default {
    components: {
    //
    },
};
</script>
